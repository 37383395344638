/**
 * Componente de confirmacion de cambios en My Template
 */

import { Modal, ModalBody, Box, Stack, Flexbox, Heading, Body, Button, StackRow } from "codekit";

import { getContext, getIsOpen, getTitle, getBody } from "../../libs/redux/slices/Confirmx/confirmSlice";
import useCfrm from "./useCfrm";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function Confirmx() {
  const title = useSelector(getTitle);
  const body = useSelector(getBody);
  const isOpen = useSelector(getIsOpen);
  const { onConfirm, onCancel } = useCfrm();
  return (
    <Modal size="sm" isOpen={isOpen} isTop>
      <ModalBody size="xl">
        <Box textAlign="center">
          <Stack gap={7}>
            {title && <Heading variant="heading-6">{title}</Heading>}
            {body && <Body variant="body-2">{body}</Body>}
            <StackRow forceWidth>
              <Flexbox justifyContent="center" alignItems="center" gap={4}>
                <Button variant="error-light" size="2l" minWidth={180} onClick={onCancel}>
                  Cancelar
                </Button>
                <Button variant="error" size="2l" minWidth={180} onClick={onConfirm}>
                  Eliminar
                </Button>
              </Flexbox>
            </StackRow>
          </Stack>
        </Box>
      </ModalBody>
    </Modal>
  );
}
