/**
 * Funcion que evalua la fuerza de la contraseña segun una serie de reglas configurables
 */

const passwordStrengthValidator = (passwordToCheck) => {
  // 1. Numero de reglas cumplidas
  let flagsPassed = 0;

  // 2.1 Al menos una miniscula
  const hasLowercase = /[a-z]+/.test(passwordToCheck);

  // 2.2 Al menos una mayuscula
  const hasUppercase = /[A-Z]+/.test(passwordToCheck);

  // 2.3 Al menos un numero
  const hasNumber = /[0-9]+/.test(passwordToCheck);

  // 2.5 Al menos (lengthRule) numero de caracteres
  const lengthRule = 8;
  const hasLength = passwordToCheck.length >= lengthRule;

  // 3. Listado de reglas por cumplir
  const flags = [hasLength, hasLowercase, hasUppercase, hasNumber];

  // 4. Numero de reglas que se deben cumplir
  const flagsMustPass = flags.length;

  // 6. Verificar reglas cumplidas
  for (const test of flags) {
    if (test) {
      flagsPassed++;
    }
  }

  // 7. Evaluacion final
  const isPasswordStrong = flagsMustPass === flagsPassed;

  const result = {
    hasLength,
    hasLowercase,
    hasUppercase,
    hasNumber,
    isPasswordStrong,
  };

  return result;
};

export default passwordStrengthValidator;
