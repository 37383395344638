/**
 * Componente de crear ticket de ayuda: Muestra un enlace para hacer redireccion al enlace
 * de generacion de ticket de ayuda
 */

import { Box, Button, CodeIcon, Description, Stack, Title } from "codekit";

const Helpcard = () => {
  return (
    <Stack alignItems="center" bg="secondary-100" rounded="md" spacing={8}>
      <Box>
        <Box mb={8}>
          <Stack justifyItems="center">
            <CodeIcon variant="help-card" color="secondary-600" width={64} />
          </Stack>
        </Box>
        <Box mb={6} textAlign="center">
          <Stack gap={6}>
            <Title variant="title-2">¿Necesitas ayuda?</Title>
            <Description variant="description-2">Haz click en el siguiente botón para crear un ticket.</Description>
          </Stack>
        </Box>
        <a
          href="https://conexiongrm.repremundo.com.co/produccion/Helpdesk/"
          rel="noreferrer"
          target="_blank"
          className="link-full"
        >
          <Button variant="info" asSpan>
            Crear un ticket
          </Button>
        </a>
      </Box>
    </Stack>
  );
};

export default Helpcard;
