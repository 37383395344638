import { createSlice } from "@reduxjs/toolkit";

export const confirmSlice = createSlice({
  name: "confirm",
  initialState: {
    isOpen: false,
    title: "",
    body: "",
  },
  reducers: {
    contextConfirm: (state, action) => {
      const { title, body } = action.payload;
      state.title = title;
      state.body = body;
    },
    closeConfrim: (state, action) => {
      state.isOpen = action.payload;
    },
    openConfirm: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { contextConfirm, closeConfrim, openConfirm } = confirmSlice.actions;

export default confirmSlice.reducer;

//Columnas para la busqueda
export const getIsOpen = (state) => {
  return state.confirm.isOpen;
};
export const getContext = (state) => {
  return {
    title: state.confirm.title,
    body: state.confirm.body,
  };
};

export const getTitle = (state) => {
  return state.confirm.title;
};
export const getBody = (state) => {
  return state.confirm.body;
};

// export const {
//   setSearchColumns,
//   setSearchEditColumns,
//   setColSelected,
//   setColSelectEdit,
//   setTemplateEdit,
//   setEditTemplate,
//   setCloneTemplate,
//   resetColSelectEdit,
//   resetConsult,
// } = consultSlice.actions;

// export default consultSlice.reducer;

// //Columnas para la busqueda
// export const getSearchColumns = (state) => {
//   return state.consult.searchColumns;
// };

// //Listado de columnas a editar
// export const getSearchEditColumns = (state) => {
//   return state.consult.searchEditColumns;
// };

// //Columnas Seleccionadas
// export const getColSelected = (state) => {
//   return state.consult.colSelected;
// };

// //Columnas seleccionadas Editar
// export const getColSelectEdit = (state) => {
//   return state.consult.colSelectEdit;
// };

// //Plantilla a Editar
// export const getTemplateEdit = (state) => {
//   return state.consult.templateEdit;
// };

// //Bandera Editar Plantilla
// export const getEditTemplate = (state) => {
//   return state.consult.editTemplate;
// };

// //Plantilla a Clonar
// export const getCloneTemplate = (state) => {
//   return state.consult.cloneTemplate;
// };
