import {
  Avatar,
  Box,
  Button,
  Divider,
  Flexbox,
  FlexboxRow,
  Heading,
  Label,
  Modal,
  ModalBody,
  Stack,
  StackRow,
} from "codekit";

import generateAvatarLetters from "../utils/avatar-letters-generator";
import { convertString } from "../utils/globalFunctions";
import { useState } from "react";
import { useGetPermissionUserReplaceMutation } from "../libs/redux/slices/authSlice/authApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getListTemporalUsers,
  setInfoUserReplace,
  setIsReplaceUser,
  setListTemporalUsers,
  setMenuUserReplace,
  setUserReplace,
} from "../libs/redux/slices/authSlice/authSlice";

const Usertmpx = ({ handleClickHome }) => {
  const dispatch = useDispatch();
  const [isOpenAlertInfo, setIsOpenAlertInfo] = useState(false);
  const [user, setUser] = useState("");
  const [userName, setUserName] = useState("");
  const [getPermissionUserReplace, { isLoading }] = useGetPermissionUserReplaceMutation();
  const temporalUsers = useSelector(getListTemporalUsers);

  const handleChangeUser = async () => {
    const dataUser = temporalUsers.filter((item) => item.USRIDTXX === user);
    await getPermissionUserReplace({ USRIDXXX: user })
      .unwrap()
      .then((response) => {
        dispatch(setIsReplaceUser(!dataUser[0]?.ORIGINAL));
        dispatch(setMenuUserReplace(response.data));
        dispatch(setUserReplace(user));
        dispatch(setInfoUserReplace({ ...dataUser[0], USRIDXXX: dataUser[0].USRIDTXX }));
        dispatch(setListTemporalUsers());
        setIsOpenAlertInfo(false);
        handleClickHome();
      })
      .catch();
  };

  return temporalUsers.length > 0 ? (
    <>
      <Divider />
      <Box mt={2}>
        <Label weight="semibold">Cambiar de perfil</Label>
        {temporalUsers.map((item, index) => (
          <Box
            key={`user-${index}`}
            mb={4}
            rounded="md"
            bg="secondary-200"
            spacing={2}
            onClick={() => {
              setIsOpenAlertInfo(true);
              setUser(item.USRIDTXX);
              setUserName(convertString(`${item.USRNOMXX}`));
            }}
          >
            <Flexbox alignItems="center" justifyContent="start" gap={3}>
              <FlexboxRow style={{ cursor: "pointer" }}>
                <Avatar
                  size="md"
                  image={`${
                    item.USRURLFO && item.USRURLFO !== "" ? item?.USRURLFO : generateAvatarLetters(`${item.USRNOMXX}`)
                  }`}
                />
              </FlexboxRow>
              <FlexboxRow style={{ overflow: "hidden", cursor: "pointer" }}>
                <Label color="secondary-600" weight="bold">
                  <p style={{ cursor: "pointer" }}>{convertString(`${item.USRNOMXX}`)}</p>
                  <p
                    title={item.USREMAXX.toLowerCase()}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "normal",
                      color: "black",
                      cursor: "pointer",
                    }}
                  >
                    {item.USREMAXX.toLowerCase()}
                  </p>
                </Label>
              </FlexboxRow>
            </Flexbox>
          </Box>
        ))}
      </Box>
      <Modal size="sm" isOpen={isOpenAlertInfo}>
        <ModalBody size="xl">
          <Box textAlign="center">
            <Stack gap={7}>
              <Heading variant="heading-6">{`¿Esta seguro que desea cambiar al perfil de ${userName} ?`}</Heading>
              <StackRow forceWidth>
                <Flexbox justifyContent="center" alignItems="center" gap={4}>
                  <Button
                    variant="secondary"
                    size="2l"
                    minWidth={180}
                    onClick={() => {
                      setIsOpenAlertInfo(false);
                    }}
                    inactive={isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="secondary-bold"
                    size="2l"
                    minWidth={180}
                    onClick={() => {
                      handleChangeUser(false);
                    }}
                    isLoading={isLoading}
                  >
                    Aceptar
                  </Button>
                </Flexbox>
              </StackRow>
            </Stack>
          </Box>
        </ModalBody>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default Usertmpx;
