/**
 * Slice para el maneja del estado para la parametrica de Centro y subcentro de costo
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  valueMask: {},
};
const centcostSlice = createSlice({
  name: "centcost",
  initialState: initialState,
  reducers: {
    setValueMask: (state, action) => {
      state.valueMask = action.payload;
    },
    resetValues: () => initialState,
  },
});

export const { setValueMask, resetValues } = centcostSlice.actions;

export default centcostSlice.reducer;

export const getValueMask = (state) => {
  return state.centcost.valueMask;
};
