import { createSlice } from "@reduxjs/toolkit";

const exportExcSlice = createSlice({
  name: "exportexcl",
  initialState: {
    searchColumns: [],
    searchEditColumns: [],
    colSelected: [],
    colSelectEdit: [],
    templateEdit: [],
    editTemplate: false,
    cloneTemplate: false,
    ColumnsConsult: [],
    bReporProgram: false,
    originalColumnsExport: {},
  },
  reducers: {
    setSearchColumns: (state, action) => {
      state.searchColumns = action.payload;
    },
    setSearchEditColumns: (state, action) => {
      state.searchEditColumns = action.payload;
    },
    setColSelected: (state, action) => {
      state.colSelected = action.payload;
    },
    setColSelectEdit: (state, action) => {
      state.colSelectEdit = action.payload;
    },
    setTemplateEdit: (state, action) => {
      state.templateEdit = action.payload;
    },
    setEditTemplate: (state, action) => {
      state.editTemplate = action.payload;
    },
    setCloneTemplate: (state, action) => {
      state.cloneTemplate = action.payload;
    },
    resetColSelectEdit: (state) => {
      state.colSelectEdit = [];
    },
    setColumnsConsult: (state, action) => {
      state.ColumnsConsult = action.payload;
    },
    resetExport: (state) => {
      state.colSelected = [];
      state.colSelectEdit = [];
    },
    setOriginalColumnsExport:(state, action) => {
      state.originalColumnsExport = action.payload;
    },
  },
});

export const {
  setSearchColumns,
  setSearchEditColumns,
  setColSelected,
  setColSelectEdit,
  setTemplateEdit,
  setEditTemplate,
  setCloneTemplate,
  resetColSelectEdit,
  setColumnsConsult,
  resetExport,
  setOriginalColumnsExport,
} = exportExcSlice.actions;

export default exportExcSlice.reducer;
//Columnas para la busqueda
export const getSearchColumns = (state) => {
  return state.exportexcl.searchColumns;
};

export const getColumnsConsult = (state) => {
  return state.exportexcl.ColumnsConsult;
};

//Listado de columnas a editar
export const getSearchEditColumns = (state) => {
  return state.exportexcl.searchEditColumns;
};

//Columnas Seleccionadas
export const getColSelected = (state) => {
  return state.exportexcl.colSelected;
};

//Columnas seleccionadas Editar
export const getColSelectEdit = (state) => {
  return state.exportexcl.colSelectEdit;
};

//Plantilla a Editar
export const getTemplateEdit = (state) => {
  return state.exportexcl.templateEdit;
};

//Bandera Editar Plantilla
export const getEditTemplate = (state) => {
  return state.exportexcl.editTemplate;
};

//Plantilla a Clonar
export const getCloneTemplate = (state) => {
  return state.exportexcl.cloneTemplate;
};

//Columnas iniciales del exportar
export const getOriginalColumnsExport = (state) => {
  return state.exportexcl.originalColumnsExport;
};
