/**
 * Funcion que capitaliza cada palabra de una string
 */

const capitalizeWords = (str) => {
  const text = str.toLowerCase();
  const capitalized = text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return capitalized;
};

export default capitalizeWords;
