import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filters: [],
  withRedirect: false,
  MODIDXXX: "",
  PROIDXXX: "",
};

const graficsxSlice = createSlice({
  name: "graficsx",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setRedirectGraph: (state, action) => {
      const { MODIDXXX, PROIDXXX } = action.payload;
      state.MODIDXXX = MODIDXXX;
      state.PROIDXXX = PROIDXXX;
      state.withRedirect = true;
    },
    unsetRedirectGraph: (state) => {
      state.MODIDXXX = "";
      state.PROIDXXX = "";
      state.withRedirect = false;
    },
    resetFiltersGraph: (state) => {
      state.filters = [];
    },
  },
});

export const { setFilters, setRedirectGraph, unsetRedirectGraph, resetFiltersGraph } = graficsxSlice.actions;

export default graficsxSlice.reducer;

export const getFilters = (state) => {
  return state.graphPersistReducer.filters;
};

export const getWithRedirect = (state) => {
  return state.graphPersistReducer.withRedirect;
};
