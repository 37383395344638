import { useState } from "react";

export function useActionsGraphRangetFilters() {
  const [valueFilter, setValueFilter] = useState([]);

  const convertRangeDate = (rangeDateArray) => {
    const ragesDate = rangeDateArray.map((date) => {
      const splitDate = date.split("/");
      const day = splitDate[0];
      const month = splitDate[1];
      const year = splitDate[2];
      return `${year}/${month}/${day}`;
    });
    return ragesDate;
  };

  const hadleSetValue = (context) => {
    setValueFilter(convertRangeDate(context.option));
  };

  const clsFilter = () => {
    setValueFilter([]);
  };

  return [valueFilter, hadleSetValue, clsFilter, convertRangeDate];
}
