import { Taskgraf } from "./Graphics/Taskgraf";

/**
 * Componente para seleccionar la grafica a pintar
 */
export function Graphsel({ graphName, graphComponent, MODIDXXX, PROIDXXX, graphKeyName, filtersFieldsGraph }) {
  const properties = {
    graphName,
    MODIDXXX,
    PROIDXXX,
    graphKeyName,
    filtersFieldsGraph,
  };
  const types = {
    Taskgraf: <Taskgraf {...properties}></Taskgraf>,
  };

  return <>{types[graphComponent]}</>;
}
