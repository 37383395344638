/**
 * Slice para el manejo del estado oferta comercial
 */
import { createSlice } from "@reduxjs/toolkit";

export const baseRanges = { VALINFXX: "", VALSUPXX: "", VLRRANGO: "" };

const baseFieldTar = {
  CONCOBID: "",
  CONCOBDE: null,
  FORCALID: null,
  FORCALDE: null,
  FORCALCA: "",
  SUCIDXXX: [],
  FOTTDOCX: [],
  VARIDXXX: "",
  VARDESXX: "",
  CAMPIDXX: [],
  RANGOSXX: [baseRanges],
  FOTCONPX: "",
  FOTDECON: "",
  FOTOBSVX: "",
};

export const valuesTarifa = {
  IMPORTACION: [baseFieldTar],
  EXPORTACION: [baseFieldTar],
  TRANSITO: [baseFieldTar],
  REGISTRO: [baseFieldTar],
  PROYECTO: [[baseFieldTar]],
};

const baseFields = {
  FICINDID: null,
  FICINDDE: null,
  LIMINDSU: "",
  LIMINDIN: "",
  UNIMEDID: null,
  METTIPXX: "",
  METFORXX: "",
  VARMEIDP: null,
  FOIORANG: [baseRanges],
};

const baseKpi = {
  TOKDESPX: "",
  TOKIDXXX: "",
  MODTRAXX: [],
  SUCINDXX: [],
  INDICAXX: [baseFields],
};

const baseTipoper = {
  BANINGEX: "",
  KPIXXXXX: [baseKpi],
};

const valuesIndicator = {
  IMPORTACION: [baseTipoper],
  EXPORTACION: [baseTipoper],
  TRANSITO: [baseTipoper],
  REGISTRO: [baseTipoper],
};

export const baseServices = { CONCODES: "", FORCADES: "", FOTOBSVX: "" };
export const baseAddServices = baseFieldTar;

const initialState = {
  isSaving: false,
  consecutive: "",
  statusOffer: "",
  versionOffer: 0.0,
  //Seccion informacion general
  offerType: "",
  logisticAlly: "",
  billingAlly: "",
  operationType: [],
  valuePerson: "",
  valueDocument: "",
  idProvider: "",
  telClient: "",
  valueClient: {},
  autorizatedRate: {},
  valueGroup: "",
  descriptionGroup: "",
  isGroup: "",
  isAlly: "",
  valueAlly: null,
  alliedRate: "",
  alliedCommission: "",
  executiveCommission: "",
  paymentForm: "",
  advanceType: "",
  quotaType: "",
  responsibleTax: "",
  telComerClient: {},
  ownIncome: "",
  paymentsThird: "",
  quotaOperation: "",
  totalLimit: "",
  descriptionPerson: "",
  descriptionDocument: "",

  //Seccion tarifas
  applyRate: "",
  incrementType: "",
  typeDate: "",
  fieldsOperation: valuesTarifa,
  initialDate: "",
  isSubmitRate: false,
  applyProject: "",
  nameProject: "",

  //Seccion indicadores
  indicatorsOperation: valuesIndicator,
  isKpiStandard: {},
  isSubmitIndicators: false,

  //Seccion alcance del servicio
  generalChecks: {},
  gestionChecks: {},
  modelChecks: {},
  conditionChecks: {},
  rateType: "",
  otherGeneral: [""],
  otherCondition: [""],
  generalScope: [],
  gestionScope: [],
  modelScope: [],
  dataCondition: [],

  //Estados necesarios para el PDF
  generalInformation: {},
  isSetData: false,
  conditions: {},
  specialCondition: {},

  isUpdateRate: false,
  isDataProject: false,
  variablesIndicator: [],
  applyKpiOperation: {},
  country: "",
  department: "",
  dataServices: [baseServices],
  dataAddService: [baseAddServices],
  variables: {},
  variablesRate: { IMPORTACION: [], EXPORTACION: [], TRANSITO: [], REGISTRO: [] },
  currency: "",
  isRateAlly: false,
};

const ofercomeSlice = createSlice({
  name: "ofercome",
  initialState: initialState,
  reducers: {
    setIsSaving: (state, action) => {
      state.isSaving = action.payload;
    },
    setConsecutive: (state, action) => {
      state.consecutive = action.payload;
    },
    setStatusOffer: (state, action) => {
      state.statusOffer = action.payload;
    },
    setVersionOffer: (state, action) => {
      state.versionOffer = action.payload;
    },
    //Seccion informacion general
    setOfferType: (state, action) => {
      state.offerType = action.payload;
    },
    setLogisticAlly: (state, action) => {
      state.logisticAlly = action.payload;
    },
    setBillingAlly: (state, action) => {
      state.billingAlly = action.payload;
    },
    setValuePerson: (state, action) => {
      state.valuePerson = action.payload;
    },
    setValueDocument: (state, action) => {
      state.valueDocument = action.payload;
    },
    setIdProvider: (state, action) => {
      state.idProvider = action.payload;
    },
    setTelClient: (state, action) => {
      state.telClient = action.payload;
    },
    setValueClient: (state, action) => {
      state.valueClient = action.payload;
    },
    setAutorizatedRate: (state, action) => {
      state.autorizatedRate = action.payload;
    },
    setValueGroup: (state, action) => {
      state.valueGroup = action.payload;
    },
    setDescriptionGroup: (state, action) => {
      state.descriptionGroup = action.payload;
    },
    setIsGroup: (state, action) => {
      state.isGroup = action.payload;
    },
    setIsAlly: (state, action) => {
      state.isAlly = action.payload;
    },
    setValueAlly: (state, action) => {
      state.valueAlly = action.payload;
    },
    setAlliedRates: (state, action) => {
      state.alliedRate = action.payload;
    },
    setAlliedCommission: (state, action) => {
      state.alliedCommission = action.payload;
    },
    setExecutiveCommission: (state, action) => {
      state.executiveCommission = action.payload;
    },
    setPaymentForm: (state, action) => {
      state.paymentForm = action.payload;
    },
    setAdvanceType: (state, action) => {
      state.advanceType = action.payload;
    },
    setQuotaType: (state, action) => {
      state.quotaType = action.payload;
    },
    setResponsibleTax: (state, action) => {
      state.responsibleTax = action.payload;
    },
    setOperationType: (state, action) => {
      state.operationType = action.payload;
    },
    setDescriptionPerson: (state, action) => {
      state.descriptionPerson = action.payload;
    },
    setDescriptionDocument: (state, action) => {
      state.descriptionDocument = action.payload;
    },
    //Seccion tarifas
    setApplyRate: (state, action) => {
      state.applyRate = action.payload;
    },
    setIncrementType: (state, action) => {
      state.incrementType = action.payload;
    },
    setTypeDate: (state, action) => {
      state.typeDate = action.payload;
    },
    setFieldsOperation: (state, action) => {
      const { newFields, operation, index } = action.payload;
      if (operation !== "PROYECTO") {
        state.fieldsOperation[operation] = newFields;
      } else {
        if (index !== undefined) {
          state.fieldsOperation[operation][index] = newFields;
        } else {
          state.fieldsOperation[operation] = newFields;
        }
      }
    },
    appendFieldsOperation: (state, action) => {
      const { operation } = action.payload;
      if (operation !== "PROYECTO") {
        state.fieldsOperation[operation].push({ ...baseFieldTar });
      } else {
        state.fieldsOperation[operation].push([{ ...baseFieldTar }]);
      }
    },
    appendConceptProject: (state, action) => {
      const { index } = action.payload;
      state.fieldsOperation["PROYECTO"][index].push({ ...baseFieldTar });
    },
    setInitialDate: (state, action) => {
      state.initialDate = action.payload;
    },
    setIsSubmitRate: (state, action) => {
      state.isSubmitRate = action.payload;
    },
    setApplyProject: (state, action) => {
      state.applyProject = action.payload;
    },
    setNameProject: (state, action) => {
      state.nameProject = action.payload;
    },
    //Indicadores
    setChangeStandar: (state, action) => {
      const { operation, value } = action.payload;
      state.indicatorsOperation[operation] = [{ BANINGEX: value, KPIXXXXX: [baseKpi] }];
    },
    setIndicatorsOperation: (state, action) => {
      const { newFields, operation, indKpi } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX[indKpi].INDICAXX = newFields;
    },
    setAddKpiByOperation: (state, action) => {
      const { operation } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX.push(baseKpi);
    },
    setKpiByIndicatorOperation: (state, action) => {
      const { newKpi, operation } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX = newKpi;
    },
    setChangeLevelKpi: (state, action) => {
      const { operation, keyKpi, changeKpi } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX[keyKpi] = changeKpi;
    },
    setChangeLevelIndicax: (state, action) => {
      const { operation, keyKpi, keyIndi, changeIndi } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX[keyKpi].INDICAXX[keyIndi] = changeIndi;
    },
    appendIndicatorsOperation: (state, action) => {
      const { operation, indkpi } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX[indkpi].INDICAXX.push(baseFields);
    },
    setAddRangesByIndi: (state, action) => {
      const { operation, indkpi, index } = action.payload;
      const ranges = state.indicatorsOperation[operation][0].KPIXXXXX[indkpi].INDICAXX[index].FOIORANG;
      if (ranges === "null" || !ranges) {
        state.indicatorsOperation[operation][0].KPIXXXXX[indkpi].INDICAXX[index].FOIORANG = [baseRanges];
      } else {
        state.indicatorsOperation[operation][0].KPIXXXXX[indkpi].INDICAXX[index].FOIORANG.push(baseRanges);
      }
    },
    setRemoveRange: (state, action) => {
      const { operation, keyKpi, keyIndi, newFieldsRanges } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX[keyKpi].INDICAXX[keyIndi].FOIORANG = newFieldsRanges;
    },
    setChangeLevelRange: (state, action) => {
      const { operation, keyKpi, keyIndi, keyRange, changeRanges } = action.payload;
      state.indicatorsOperation[operation][0].KPIXXXXX[keyKpi].INDICAXX[keyIndi].FOIORANG[keyRange] = changeRanges;
    },
    setIsSubmitIndicators: (state, action) => {
      state.isSubmitIndicators = action.payload;
    },
    //Seccion alcance del servicio
    setGeneralChecks: (state, action) => {
      state.generalChecks = action.payload;
    },
    setGestionChecks: (state, action) => {
      state.gestionChecks = action.payload;
    },
    setModelChecks: (state, action) => {
      state.modelChecks = action.payload;
    },
    setConditionChecks: (state, action) => {
      state.conditionChecks = action.payload;
    },
    setTelComerClient: (state, action) => {
      const { value, index } = action.payload;
      state.telComerClient = { ...state.telComerClient, [index]: value };
    },
    setOwnIncome: (state, action) => {
      state.ownIncome = action.payload;
    },
    setPaymentsThird: (state, action) => {
      state.paymentsThird = action.payload;
    },
    setQuotaOperation: (state, action) => {
      state.quotaOperation = action.payload;
    },
    setTotalLimit: (state, action) => {
      state.totalLimit = action.payload;
    },
    setRateType: (state, action) => {
      state.rateType = action.payload;
    },
    setOtherGeneral: (state, action) => {
      state.otherGeneral = action.payload;
    },
    appendOtherGeneral: (state) => {
      state.otherGeneral.push("");
    },
    setOtherCondition: (state, action) => {
      state.otherCondition = action.payload;
    },
    appendOtherCondition: (state) => {
      state.otherCondition.push("");
    },
    resetValues: (state) => initialState,
    setGeneralInformation: (state, action) => {
      state.generalInformation = action.payload;
    },
    setIsSetData: (state, action) => {
      state.isSetData = action.payload;
    },
    setConditions: (state, action) => {
      state.conditions = action.payload;
    },
    setSpecialConditions: (state, action) => {
      state.specialCondition = action.payload;
    },
    setGeneralScope: (state, action) => {
      state.generalScope = action.payload;
    },
    setGestionScope: (state, action) => {
      state.gestionScope = action.payload;
    },
    setModelScope: (state, action) => {
      state.modelScope = action.payload;
    },
    setDataCondition: (state, action) => {
      state.dataCondition = action.payload;
    },
    setRateStandar: (state, action) => {
      const { data } = action.payload;
      let newData = {
        IMPORTACION: [],
        EXPORTACION: [],
        TRANSITO: [],
        REGISTRO: [],
        PROYECTO: [...state.fieldsOperation["PROYECTO"]],
      };
      data.forEach((element) => {
        newData[element.DOCTIPXX].push({ ...baseFieldTar, CONCOBID: element.CONCOBID, CONCOBDE: element.CONCOBDE });
      });
      state.fieldsOperation = newData;
    },
    resetRateStandar: (state) => {
      const valuesTarifa = {
        IMPORTACION: [baseFieldTar],
        EXPORTACION: [baseFieldTar],
        TRANSITO: [baseFieldTar],
        REGISTRO: [baseFieldTar],
        PROYECTO: [...state.fieldsOperation["PROYECTO"]],
      };
      state.fieldsOperation = valuesTarifa;
    },
    //Actualizar bandera Actualizar Tarifas
    setIsUpdateRate: (state, action) => {
      state.isUpdateRate = action.payload;
    },
    setIsDataProject: (state, action) => {
      state.isDataProject = action.payload;
    },
    setVariablesIndicator: (state, action) => {
      state.variablesIndicator = action.payload;
    },
    setApplyKpiOperation: (state, action) => {
      const { operation, value } = action.payload;
      state.applyKpiOperation = { ...state.applyKpiOperation, [operation]: value };
    },
    resetIndicatorsOperation: (state, action) => {
      const { operation } = action.payload;
      state.indicatorsOperation[operation] = valuesIndicator[operation];
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },
    setDataServices: (state, action) => {
      state.dataServices = action.payload;
    },
    setDataAddService: (state, action) => {
      state.dataAddService = action.payload;
    },
    setVariables: (state, action) => {
      const { index, data } = action.payload;
      state.variables = { ...state.variables, [index]: data };
    },
    setVariablesRate: (state, action) => {
      const { index, data, operation } = action.payload;
      state.variablesRate[operation] = { ...state.variablesRate[operation], [index]: data };
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setIsRateAlly: (state, action) => {
      state.isRateAlly = action.payload;
    },
  },
});

export const {
  setIsSaving,
  setConsecutive,
  setStatusOffer,
  setVersionOffer,
  setOfferType,
  setLogisticAlly,
  setBillingAlly,
  setValuePerson,
  setValueDocument,
  setIdProvider,
  setTelClient,
  setValueClient,
  setAutorizatedRate,
  setValueGroup,
  setIsGroup,
  setIsAlly,
  setValueAlly,
  setAlliedRates,
  setAlliedCommission,
  setExecutiveCommission,
  setPaymentForm,
  setAdvanceType,
  setQuotaType,
  setResponsibleTax,
  setOperationType,
  setFieldsOperation,
  appendFieldsOperation,
  appendConceptProject,
  setApplyRate,
  setIncrementType,
  setTypeDate,
  setChangeStandar,
  setIndicatorsOperation,
  appendIndicatorsOperation,
  setGeneralChecks,
  setGestionChecks,
  setModelChecks,
  setConditionChecks,
  setTelComerClient,
  setOwnIncome,
  setPaymentsThird,
  setQuotaOperation,
  setTotalLimit,
  setInitialDate,
  setIsSubmitRate,
  setRateType,
  setOtherGeneral,
  resetValues,
  appendOtherGeneral,
  setOtherCondition,
  appendOtherCondition,
  setIsSubmitIndicators,
  setApplyProject,
  setNameProject,
  setGeneralInformation,
  setIsSetData,
  setConditions,
  setSpecialConditions,
  setGeneralScope,
  setGestionScope,
  setModelScope,
  setDataCondition,
  setDescriptionPerson,
  setDescriptionDocument,
  setDescriptionGroup,
  setRateStandar,
  resetRateStandar,
  setIsUpdateRate,
  setAddRangesByIndi,
  setIsDataProject,
  setAddKpiByOperation,
  setKpiByIndicatorOperation,
  setChangeLevelKpi,
  setChangeLevelIndicax,
  setRemoveRange,
  setChangeLevelRange,
  setVariablesIndicator,
  setApplyKpiOperation,
  resetIndicatorsOperation,
  setCountry,
  setDepartment,
  setDataServices,
  setDataAddService,
  setVariables,
  setVariablesRate,
  setCurrency,
  setIsRateAlly,
} = ofercomeSlice.actions;

export default ofercomeSlice.reducer;
export const getIsSaving = (state) => {
  return state.ofercome.isSaving;
};
export const getConsecutive = (state) => {
  return state.ofercome.consecutive;
};
//Seccion informacion general
export const getOfferType = (state) => {
  return state.ofercome.offerType;
};
export const getLogisticAlly = (state) => {
  return state.ofercome.logisticAlly;
};
export const getBillingAlly = (state) => {
  return state.ofercome.billingAlly;
};
export const getValuePerson = (state) => {
  return state.ofercome.valuePerson;
};
export const getValueDocument = (state) => {
  return state.ofercome.valueDocument;
};
export const getIdProvider = (state) => {
  return state.ofercome.idProvider;
};
export const getTelClient = (state) => {
  return state.ofercome.telClient;
};
export const getValueClient = (state) => {
  return state.ofercome.valueClient;
};
export const getAutorizatedRate = (state) => {
  return state.ofercome.autorizatedRate;
};
export const getValueGroup = (state) => {
  return state.ofercome.valueGroup;
};
export const getIsGroup = (state) => {
  return state.ofercome.isGroup;
};
export const getIsAlly = (state) => {
  return state.ofercome.isAlly;
};
export const getValueAlly = (state) => {
  return state.ofercome.valueAlly;
};
export const getAlliedRates = (state) => {
  return state.ofercome.alliedRate;
};
export const getAlliedCommission = (state) => {
  return state.ofercome.alliedCommission;
};
export const getExecutiveCommission = (state) => {
  return state.ofercome.executiveCommission;
};
export const getPaymentForm = (state) => {
  return state.ofercome.paymentForm;
};
export const getAdvanceType = (state) => {
  return state.ofercome.advanceType;
};
export const getQuotaType = (state) => {
  return state.ofercome.quotaType;
};
export const getResponsibleTax = (state) => {
  return state.ofercome.responsibleTax;
};
export const getOperationType = (state) => {
  return state.ofercome.operationType;
};
export const getTelComerClient = (state) => {
  return state.ofercome.telComerClient;
};
export const getOwnIncome = (state) => {
  return state.ofercome.ownIncome;
};
export const getPaymentsThird = (state) => {
  return state.ofercome.paymentsThird;
};
export const getQuotaOperation = (state) => {
  return state.ofercome.quotaOperation;
};
export const getTotalLimit = (state) => {
  return state.ofercome.totalLimit;
};

//Seccion tarifas
export const getApplyRate = (state) => {
  return state.ofercome.applyRate;
};
export const getIncrementType = (state) => {
  return state.ofercome.incrementType;
};
export const getTypeDate = (state) => {
  return state.ofercome.typeDate;
};
export const getFieldsOperation = (state) => {
  return state.ofercome.fieldsOperation;
};
export const getInitialDate = (state) => {
  return state.ofercome.initialDate;
};
export const getIsSubmitRate = (state) => {
  return state.ofercome.isSubmitRate;
};
export const getApplyProject = (state) => {
  return state.ofercome.applyProject;
};
export const getNameProject = (state) => {
  return state.ofercome.nameProject;
};

//Seccion indicadores
export const getIndicatorsOperation = (state) => {
  return state.ofercome.indicatorsOperation;
};
export const getisKpiStandard = (state) => {
  return state.ofercome.isKpiStandard;
};
export const getIsSubmitIndicator = (state) => {
  return state.ofercome.isSubmitIndicators;
};

//Seccion alcance del servicio
export const getGeneralChecks = (state) => {
  return state.ofercome.generalChecks;
};
export const getGestionChecks = (state) => {
  return state.ofercome.gestionChecks;
};
export const getModelChecks = (state) => {
  return state.ofercome.modelChecks;
};
export const getConditionChecks = (state) => {
  return state.ofercome.conditionChecks;
};
export const getRateType = (state) => {
  return state.ofercome.rateType;
};
export const getOtherGeneral = (state) => {
  return state.ofercome.otherGeneral;
};
export const getOtherCondition = (state) => {
  return state.ofercome.otherCondition;
};

export const getAllState = (state) => {
  return state.ofercome;
};

export const getGeneralInformation = (state) => {
  return state.ofercome.generalInformation;
};

export const getIsUpdateRate = (state) => {
  return state.ofercome.isUpdateRate;
};

export const getApplyKpiOperation = (state) => {
  return state.ofercome.applyKpiOperation;
};

export const getVariables = (state) => {
  return state.ofercome.variables;
};

export const getVariablesRate = (state) => {
  return state.ofercome.variablesRate;
};
