import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { signOut } from "./authSlice/authSlice";
import { resetConsult } from "./consultSlice/consultSlice";
import { shootToast } from "codekit";
import QueryString from "qs";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CIMA_ENDPOINT,
  mode: "cors",
  credentials: "include",
  timeout: process.env.REACT_APP_CIMA_TIMEOUT,
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().persistedReducer.token;
    const module = getState().persistedReducer.moduleURL;
    Headers.set("Accept", "application/json");
    if (token) {
      Headers.set("Authorization", `Bearer ${token}`);
    }
    if (module && !Headers.has("modulecima")) {
      Headers.set("modulecima", module);
    }
    return Headers;
  },
  paramsSerializer: (params) => {
    return QueryString.stringify(params);
  },
});

const baseQueryWithLogOut = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  let autoHide = true;
  let text = "";
  let variant = "";

  const statusCode = result?.error?.status;
  const responseStatusCode = result?.meta?.response?.status;

  switch (statusCode) {
    case "TIMEOUT_ERROR":
      text = "No se pudo conectar con la plataforma. Por favor, intenta de nuevo más tarde.";
      variant = "error";
      break;
    case 401:
      api.dispatch(signOut());
      break;
    case 403:
    case 405:
    case 422:
    case 500:
      const errors = result?.error?.data;
      if (errors?.message) {
        text = errors?.message;
      } else if (errors.errors) {
        const errorObject = errors.errors;
        const errorsProperties = Object.getOwnPropertyNames(errorObject);
        errorsProperties.forEach((property) => {
          if (property !== "exception") {
            text +=
              errorObject[property] !== "Invalid Query"
                ? errorObject[property]
                : "Se presentaron problemas en la consulta";
            text += "\n";
          } else {
            text += errorObject[property].includes("Duplicate entry")
              ? "La informacion que intenta guardar ya existe"
              : errorObject[property];
          }
        });
      } else {
        text = "Error no identificado";
      }
      variant = "error";
      break;
    default:
      break;
  }

  switch (responseStatusCode) {
    case 201:
      //Limpio filtros consulta inducida ???
      api.dispatch(resetConsult());

      text = "La informacion ha sido cargada con exito!";
      variant = "success";
      break;
    case 200:
      const method = result?.meta?.request?.method;
      variant = "success";

      if (method === "PUT") {
        text = "El registro ha sido actualizado con exito!";
      }

      if (method === "DELETE") {
        text = result.data?.data?.message;
      }

      // if(method === "POST") {
      //   text = "La informacion ha sido cargada con exito!";
      // }

      break;
    default:
      break;
  }

  if (text !== "") {
    shootToast({
      variant,
      text,
      duration: 5000,
      autoHide,
    });
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithLogOut,
  endpoints: (builder) => ({}),
  tagTypes: ["Create", "Consult", "Notification"],
});
