import { Stack, StackRow } from "codekit";
import Changpwd from "../../components/Changpwd/Changpwd";
import Pwdrules from "../../components/Pwdrules/Pwdrules";

const Chanpass = () => {

  return (
    <Stack columnsVariant="form-2">
      <StackRow>
        <Changpwd origin="LOGIN-PAGE" />
      </StackRow>
      <StackRow>
        <Pwdrules />
      </StackRow>
    </Stack>
  )
}
export default Chanpass;
