/**
 * Slice para el manejo del estado Anticipo cliente
 */
import { createSlice } from "@reduxjs/toolkit";

const notificxSlice = createSlice({
  name: "notificx",
  initialState: {
    page: 1,
    dataNotification: [],
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setDataNotification: (state, action) => {
      state.dataNotification = action.payload;
    },
  },
});

export const { setPage, setDataNotification } = notificxSlice.actions;

export default notificxSlice.reducer;

export const getPage = (state) => {
  return state.notificx.page;
};

export const getDataNotification = (state) => {
  return state.notificx.dataNotification;
};
