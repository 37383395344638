/**
 * Slice para el maneja del estado para el componente Ficha cliente
 */
import { createSlice } from "@reduxjs/toolkit";

//Estado inicial de cada pestaña
const statusView = {
  general: true,
  sarlaft: false,
  comercial: false,
  tributario: false,
  operativo: false,
};

const baseFields = { COCCLITO: "", COCGESXX: null, COCGEDES: null };

const fieldsOperation = {
  IMPORTACION: baseFields,
  EXPORTACION: baseFields,
  TRANSITO: baseFields,
  REGISTRO: baseFields,
};

const initialState = {
  country: "",
  department: "",
  city: "",
  businessGroup: "",
  addressEqual: "NO",
  economicActivity: "",
  fieldsOperation,
  valuePerson: null,
  valueDocument: null,
  importType: null,
  statusView,
  sectorsClient: null,
  dataAduana: null,
  demoDate: {},
  programPV: {},
  isGeneral: false,
  isComercial: false,
  isTributario: false,
  isAduanero: false,
  isCustomsVisible: false,
};

const fichacliSlice = createSlice({
  name: "fichacli",
  initialState: initialState,
  reducers: {
    setStatusShowView: (state, action) => {
      state.statusView = action.payload;
    },
    setInitialStatusShowView: (state) => {
      state.statusView = statusView;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setDepartment: (state, action) => {
      state.department = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setBusinessGroup: (state, action) => {
      state.businessGroup = action.payload;
    },
    setAddressEqual: (state, action) => {
      state.addressEqual = action.payload;
    },
    setEconomicActivity: (state, action) => {
      state.economicActivity = action.payload;
    },
    setFieldsOperation: (state, action) => {
      state.fieldsOperation = action.payload;
    },
    setValuePerson: (state, action) => {
      state.valuePerson = action.payload;
    },
    setValueDocument: (state, action) => {
      state.valueDocument = action.payload;
    },
    setImportType: (state, action) => {
      state.importType = action.payload;
    },
    resetValues: (state) => initialState,
    setSectorsClient: (state, action) => {
      state.sectorsClient = action.payload;
    },
    setDataAduana: (state, action) => {
      state.dataAduana = action.payload;
    },
    setDemoDate: (state, action) => {
      const { index, value } = action.payload;
      const actualDate = { ...state.demoDate };
      actualDate[index] = value;
      state.demoDate = actualDate;
    },
    setProgramPV: (state, action) => {
      const { index, value } = action.payload;
      const actualProgram = { ...state.programPV };
      actualProgram[index] = value;
      state.programPV = actualProgram;
    },
    setIsGeneral: (state, action) => {
      state.isGeneral = action.payload;
    },
    setIsComercial: (state, action) => {
      state.isComercial = action.payload;
    },
    setIsTributario: (state, action) => {
      state.isTributario = action.payload;
    },
    setIsAduanero: (state, action) => {
      state.isAduanero = action.payload;
    },
    setIsCustomsVisible: (state, action) => {
      state.isCustomsVisible = action.payload;
    },
  },
});

export const {
  setStatusShowView,
  setCountry,
  setDepartment,
  setCity,
  setBusinessGroup,
  setAddressEqual,
  setEconomicActivity,
  setFieldsOperation,
  setValuePerson,
  setValueDocument,
  setImportType,
  resetValues,
  setSectorsClient,
  setDataAduana,
  setDemoDate,
  setProgramPV,
  setInitialStatusShowView,
  setIsGeneral,
  setIsComercial,
  setIsTributario,
  setIsAduanero,
  setIsCustomsVisible,
} = fichacliSlice.actions;

export default fichacliSlice.reducer;

export const getStatusShowView = (state) => {
  return state.fichacli.statusView;
};
export const getCountry = (state) => {
  return state.fichacli.country;
};
export const getDepartment = (state) => {
  return state.fichacli.department;
};
export const getCity = (state) => {
  return state.fichacli.city;
};
export const getBusinessGroup = (state) => {
  return state.fichacli.businessGroup;
};
export const getAddressEqual = (state) => {
  return state.fichacli.addressEqual;
};
export const getEconomicActivity = (state) => {
  return state.fichacli.economicActivity;
};
export const getFieldsOperation = (state) => {
  return state.fichacli.fieldsOperation;
};
export const getValuePerson = (state) => {
  return state.fichacli.valuePerson;
};
export const getValueDocument = (state) => {
  return state.fichacli.valueDocument;
};
export const getImportType = (state) => {
  return state.fichacli.importType;
};

export const gatAllState = (state) => {
  return state.fichacli;
};
