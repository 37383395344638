/**
 * Componente de cambio de contraseña exitoso: Muestra el mensaje de cambio de contraseña exitoso
 */

import { Box, Button, Heading, Stack, CodeIcon, Body } from "codekit";

import { Link } from "react-router-dom";

const Changsuc = () => {
  return (
    <Box textAlign="center">
      <Stack gap={8}>
        <Stack justifyItems="center">
          <CodeIcon variant="success-password" width={97} color="success-500" />
        </Stack>
        <Heading variant="heading-6">Tu contraseña ha sido cambiada con exito.</Heading>
        <Body variant="body-2">Haz clic en el boton para terminar e ir al inicio de la plataforma.</Body>
        <Link to={"/login"} state={{ clearShowSuccessMessage: true }}>
          <a href="/login" className="link-full">
            <Button variant="primary-bold" isFull asSpan>
              Ir al inicio
            </Button>
          </a>
        </Link>
      </Stack>
    </Box>
  );
};

export default Changsuc;
