export function useSelect() {
  const getDataForSelect = (data, valueKey = "value", textKey = "label") => {
    const dataSelect = data.map((d) => {
      return {
        value: d[valueKey],
        text: d[textKey],
      };
    });
    return dataSelect;
  };
  return {
    getDataForSelect,
  };
}
