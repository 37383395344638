import { createSlice } from "@reduxjs/toolkit";

const genioxxxSlice = createSlice({
  name: "genio",
  initialState: {
    token: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { setToken } = genioxxxSlice.actions;
export default genioxxxSlice.reducer;

export const getToken = (state) => {
  return state.genio.token;
};
