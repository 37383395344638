import { createSlice } from "@reduxjs/toolkit";

const forgotSlice = createSlice({
  name: "forgot",
  initialState: {
    user: "",
    isSendEmail: false,
    isConfirmCode: false,
    showSuccessMessage: false,
  },
  reducers: {
    confirmSendEmail: (state, action) => {
      const { USRIDXXX } = action.payload.userData.data;
      state.user = USRIDXXX.toString();
      state.isSendEmail = true;
    },
    confirmCode: (state, action) => {
      const { USRIDXXX } = action.payload.userData.data;
      state.user = USRIDXXX.toString();
      state.isConfirmCode = true;
    },
    reset: (state) => {
      state.user = "";
      state.isSendEmail = false;
      state.isConfirmCode = false;
    },
    showSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
  },
});

export const { confirmSendEmail, confirmCode, reset, showSuccessMessage, getPasswordFormMessage } = forgotSlice.actions;
export default forgotSlice.reducer;

export const getisSendMail = (state) => {
  return state.forgot.isSendEmail;
};

export const getisConfirmCode = (state) => {
  return state.forgot.isConfirmCode;
};

export const getUser = (state) => {
  return state.forgot.user;
};

export const getShowSuccessMessage = (state) => {
  return state.forgot.showSuccessMessage;
};
