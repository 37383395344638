import { useState } from "react";

export function useActionsGraphSelectFilters() {
  const [valueFilter, setValueFilter] = useState("");
  const hadleSetValue = (value) => {
    setValueFilter(value);
  };
  const clsFilter = () => {
    setValueFilter("");
  };

  return [valueFilter, hadleSetValue, clsFilter];
}
