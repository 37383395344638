/**
 * Componente encargado de pintar un tipo de anuncio y sus canales
 * @author Sebastian Rojas <sebastian.rojas@repremundo.com.co>
 */
import React, { useState } from "react";
import { Box, Label, Checkbox, Divider } from "codekit";

const Tipanunx = ({ ANUIDXXX, ANUDESXX, channels, register, setValue }) => {
  const [newChannels, setNewChannels] = useState(channels);
  return (
    <>
      <Box mt={7} mb={7}>
        <Divider text={ANUDESXX} color="accent-600" />
        <Box border={1} mt={3} mb={3}>
          {newChannels.map((item) => {
            return (
              <>
                <Box mt={5} mb={5} spacingX={6}>
                  <Divider text={`${ANUDESXX} por ${item.CANDESXX}`} color="primary-950">
                    <Checkbox
                      {...register(`CIMS0034[${ANUIDXXX}][${item.CANIDXXX}]`)}
                      checked={item.status}
                      type="switch"
                      onChange={(event) => {
                        let channels = newChannels.map((previewItem) => {
                          let newItem = { ...previewItem };
                          if (item.CANIDXXX === previewItem.CANIDXXX && item.ANUIDXXX === previewItem.ANUIDXXX) {
                            newItem.status = event.target.checked;
                            setValue(`CIMS0034[${ANUIDXXX}][${item.CANIDXXX}]`, event.target.checked);
                          }
                          return newItem;
                        });

                        setNewChannels(channels);
                      }}
                    />
                  </Divider>
                  <br />
                  <Label weight="normal">Recibir Solicitud de {`${ANUDESXX} por ${item.CANDESXX}`}</Label>
                </Box>
              </>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default Tipanunx;
