/**
 * Componente de recuperar contraseña: Contiene el formulario para solicitar la recuperacion de la contraseÑa
 */

import { Body, Box, Button, Heading, Input, Label, Stack, StackRow } from "codekit";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { confirmSendEmail } from "../../libs/redux/slices/forgotSlice/forgotSlice";
import { useSendEmailMutation } from "../../libs/redux/slices/forgotSlice/forgotApiSlice";

const Recovpwd = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  const [sendEmail, { isLoading }] = useSendEmailMutation();

  const onSubmit = async (formData) => {
    if (isLoading) {
      return;
    }

    const { email } = formData;

    try {
      const userData = await sendEmail({ email }).unwrap();
      dispatch(confirmSendEmail({ email, userData }));
    } catch (error) {}
  };

  return (
    <Box>
      <Box textAlign="center" mb={10}>
        <Stack gap={10}>
          <Heading variant="heading-6">Recuperar contraseña</Heading>
          <Body variant="body-2">
            Introduce tu correo electrónico o cédula. Recibiras un codigo de seguridad que usaras en el siguiente paso.
          </Body>
        </Stack>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={10}>
          <StackRow>
            <Label htmlFor="email">Correo electronico ó cedula</Label>
            <Input
              id="email"
              type="text"
              disabled={isLoading}
              placeholder="P. eje. grupo.repremundo@repr..."
              {...register("email", { required: true })}
              isInvalid={errors.email}
              style={{textTransform: "none"}}
            />
          </StackRow>
          <StackRow>
            <Button type="submit" variant="primary-bold" isLoading={isLoading} disabled={!isValid} isFull>
              Enviar
            </Button>
          </StackRow>
        </Stack>
      </form>
    </Box>
  );
};

export default Recovpwd;
