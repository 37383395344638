/**
 * Componente encargado de pintar la tab de comunicaciones
 * @author Sebastian Rojas <sebastian.rojas@repremundo.com.co>
 */

import React, { useState, useEffect } from "react";
import { TableLoader, Button, Flexbox, Toast, shootToast } from "codekit";
import Tipanunx from "./Tipanunx";
import { useSelector } from "react-redux";
import {
  useGetAdvertisementsQuery,
  useGetChannelsQuery,
  useGetAdvertisementsChannelsbyUserQuery,
  useCreateAdvertisementsChannelsbyUserMutation,
  useDeleteAdvertisementsChannelsbyUserMutation,
} from "../../libs/redux/slices/ComunicxSlice/comunicxApiSlice";
import { getDataUser } from "../../libs/redux/slices/authSlice/authSlice";
import { useForm } from "react-hook-form";

const Comunicx = () => {
  const [isGeneralLoading, setIsGeneralLoading] = useState(true);
  const [advertisementsChannelsData, setAdvertisementsChannelsData] = useState([]);
  const userData = useSelector(getDataUser);
  const { data: advertisementsData, isSuccess: isSuccessAdvertisements } = useGetAdvertisementsQuery();
  const { data: channelsData, isSuccess: isSuccessChannels } = useGetChannelsQuery();
  const [createAdvertisementsChannelsbyUser, { isLoading: isSending }] =
    useCreateAdvertisementsChannelsbyUserMutation();
  const [deleteAdvertisementsChannelsbyUser, { isLoading: isSendingDelete }] =
    useDeleteAdvertisementsChannelsbyUserMutation();
  const { data, isSuccess } = useGetAdvertisementsChannelsbyUserQuery(userData.USRIDXXX);

  const { register, setValue, handleSubmit } = useForm();

  /**
   * Funcion encargada de buscar si en la parametrizacion del usuario tiene el tipo de anuncio y canal parametrizado
   * @param   Integer ANUIDXXX      -ID del anuncio
   * @param   Integer CANIDXXX      -ID del canal
   * @returns Boolean               -Bandera que indica si encontro el anuncio o no
   */
  const findAdnvertisementsChannel = (ANUIDXXX, CANIDXXX) => {
    let bReturn = false;
    data.data.forEach((userAdvertisementChannel) => {
      if (ANUIDXXX === userAdvertisementChannel.ANUIDXXX && CANIDXXX === userAdvertisementChannel.CANIDXXX) {
        bReturn = true;
      }
    });
    return bReturn;
  };

  /**
   * Funcion encargada del envio de la informacion al API
   * @param Object formData       -Objeto con los datos del formulario
   */
  const onSubmit = async (formData) => {
    let dataSend = [];
    dataSend.CIMS0034 = [];
    let isValidMail = false;
    let isValidPhone = false;
    let isSubmit = true;
    //armo la estructura que me recibe el servicio
    formData.CIMS0034.forEach((advertisement, ANUIDXXX) => {
      advertisement.forEach((value, CANIDXXX) => {
        if (value) {
          let rowSend = { USRIDXXX: userData.USRIDXXX, ANUIDXXX: ANUIDXXX, CANIDXXX: CANIDXXX };
          dataSend.CIMS0034.push(rowSend);
        }
        if (value && CANIDXXX === 100) {
          isValidMail = true;
        }
        if (value && CANIDXXX === 101) {
          isValidPhone = true;
        }
      });
    });
    //Valido si tiene mail
    if (isValidMail) {
      if (userData.USREMAXX === "") {
        shootToast({
          variant: "error",
          text: "No Cuenta con Correo Electronico Registrado",
          autoHide: true,
        });
        isSubmit = false;
      }
    }
    //Valido si tiene celular
    if (isValidPhone) {
      if (userData.USRCELUL === "") {
        shootToast({
          variant: "error",
          text: "No Cuenta con No. de Celular Registrado",
          autoHide: true,
        });
        isSubmit = false;
      }
    }
    //Realizo el submit
    if (isSubmit) {
      //Si no tiene datos es porque quiere inactivar todo
      if (dataSend.CIMS0034.length > 0) {
        await createAdvertisementsChannelsbyUser(dataSend).unwrap().then().catch();
      } else {
        await deleteAdvertisementsChannelsbyUser(userData.USRIDXXX).unwrap().then().catch();
      }
    }
  };

  /**
   * UseEffect para determinar el punto de pintado de los componentes
   */
  useEffect(() => {
    if (isSuccessAdvertisements && isSuccessChannels && isSuccess) {
      let preAdvertisementsChannelsData = advertisementsData.data.map((advertisement) => {
        let preAdvertisement = { ...advertisement };
        preAdvertisement.channels = [];
        channelsData.data.forEach((channel) => {
          let isPresent = findAdnvertisementsChannel(advertisement.ANUIDXXX, channel.CANIDXXX);
          preAdvertisement.channels.push({
            ANUIDXXX: preAdvertisement.ANUIDXXX,
            CANIDXXX: channel.CANIDXXX,
            CANDESXX: channel.CANDESXX,
            status: isPresent,
          });
        });
        return preAdvertisement;
      });
      setIsGeneralLoading(false);
      setAdvertisementsChannelsData(preAdvertisementsChannelsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdvertisements, isSuccessChannels, isSuccess]);

  return isGeneralLoading ? (
    <TableLoader />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      {advertisementsChannelsData.map((advertisement) => (
        <Tipanunx
          key={advertisement.ANUIDXXX}
          ANUIDXXX={advertisement.ANUIDXXX}
          ANUDESXX={advertisement.ANUDESXX}
          channels={advertisement.channels}
          register={register}
          setValue={setValue}
        />
      ))}
      <Flexbox justifyContent="end">
        <Button type="submit" variant="primary-bold" isLoading={isSending || isSendingDelete}>
          Guardar
        </Button>
      </Flexbox>
      <Toast />
    </form>
  );
};

export default Comunicx;
