/**
 * Slice para el manejo del estado Anticipo cliente
 */
import { createSlice } from "@reduxjs/toolkit";

const tareasxxSlice = createSlice({
  name: "tareasxx",
  initialState: {
    currentView: "detail",
    isOpenRejection: false, //Apertura modal general motivos de Rechazo
    pageFollowup: 1,
    dataFollowup: [],
  },
  reducers: {
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    setIsOpenRejection: (state, action) => {
      state.isOpenRejection = action.payload;
    },
    setPageFollowup: (state, action) => {
      state.pageFollowup = action.payload;
    },
    setDataFollowup: (state, action) => {
      state.dataFollowup = action.payload;
    },
  },
});

export const { setCurrentView, setIsOpenRejection, setPageFollowup, setDataFollowup } = tareasxxSlice.actions;

export default tareasxxSlice.reducer;

export const getCurrentView = (state) => {
  return state.tareasxx.currentView;
};
export const getIsOpenRejection = (state) => {
  return state.tareasxx.isOpenRejection;
};
export const getPageFollowup = (state) => {
  return state.tareasxx.pageFollowup;
};
export const getDataFollowup = (state) => {
  return state.tareasxx.dataFollowup;
};
