import { Box, Button, CodeIcon, Description, Dropdown, DropdownAction, Flexbox, Stack, StackRow, TableLoader } from "codekit";
import { useLazyGetSesionsDevicesQuery, useInactiveSesionDeviceMutation } from "../../libs/redux/slices/DevicesxSlice/devicesxApiSlice";
import { getDataUser } from "../../libs/redux/slices/authSlice/authSlice";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Devicesx = () => {
  const [deleteSesion, { isLoading: loadInact }] = useInactiveSesionDeviceMutation();
  const userData = useSelector(getDataUser);
  const [getSesions, { data,
    isLoading,
  }] = useLazyGetSesionsDevicesQuery();

  const delSes = async (id) => {
    const { data } = await deleteSesion({ idSes: id });
    if (data) {
      getSesions({ user: userData.USRIDXXX });
    }
  }

  useEffect(() => {
    const initQueryData = async () => {
      getSesions({ user: userData.USRIDXXX });
    }
    initQueryData();
    // eslint-disable-next-line
  }, [])


  return isLoading || loadInact ? (
    <TableLoader />
  ) : (
    <Stack gap={3}>
      <StackRow>
        <Stack gap={5} responsiveColumns={{
          initial: 1,
          small: 2,
          medium: 3,
          large: 4,
          extraLarge: 5
        }}>
          {
            data?.data.length && data?.data.map((row, index) => (
              <Box key={index} rounded="lg" bg='secondary-100' spacingX={5} spacingTop={6} spacingBottom={10}>
                <Flexbox gap={4} justifyContent='end' >
                  {
                    index === 0 && (
                      <Box mb={5} rounded='md'>
                        <Button icon="circle-check" variant="accent" size="s">
                          Este Dispositivo
                        </Button>
                      </Box>
                    )
                  }
                  <Box rounded='md'>
                    <Dropdown variant='actions' button={<Button tooltipDirection="left" variant='secondary-light' icon='menu-context' onlyIcon size='s' />}>
                      <DropdownAction icon='trash' onClick={() => delSes(row.id)}>Cerrar sesión</DropdownAction>
                    </Dropdown>
                  </Box>
                </Flexbox>
                <Flexbox gap={5} justifyContent='center'>
                  <CodeIcon variant="computer" width={100} />
                </Flexbox>
                <Flexbox bg='secondary-200' justifyContent='center'>
                  <Box mt={5} textAlign='center'>
                    <Description><strong>{row.USERAGEN}</strong></Description>
                  </Box>
                </Flexbox>
                <Box borderBottom={1} mt={4} mb={4} spacingBottom={4} borderColor='secondary-600' />
                <Box textAlign='center'>
                  <Description>Ultima vez activo: {row.last_used_at}</Description>
                </Box>
              </Box>
            ))
          }
        </Stack>
      </StackRow>
    </Stack>
  )
}
export default Devicesx;
