import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    isShowMenuModule: false,
    isShowMenuProcess: false,
    moduleSelected: "",
    procesSelected: "",
    showModalReplace: false,
    showModalDevices: false,
  },
  reducers: {
    showMenuModule: (state, action) => {
      if (action.payload !== "outside") {
        state.isShowMenuModule = state.isShowMenuModule ? false : true;
      } else {
        state.isShowMenuModule = false;
      }
    },
    reset: (state) => {
      state.isShowMenuModule = false;
      state.isShowMenuProcess = false;
      state.moduleSelected = "";
      state.procesSelected = "";
    },
    setModuleSelected: (state, action) => {
      state.moduleSelected = action.payload;
    },
    setProcesSelected: (state, action) => {
      state.procesSelected = action.payload;
    },
    setShowMenuModule: (state) => {
      state.isShowMenuModule = !state.isShowMenuModule;
    },
    setShowMenuProcess: (state, action) => {
      state.isShowMenuProcess = action.payload;
    },
    setShowModalReplace: (state) => {
      state.showModalReplace = !state.showModalReplace;
    },
    setShowModalDevices: (state) => {
      state.showModalDevices = !state.showModalDevices;
    },
  },
});

export const {
  showMenuModule,
  reset,
  setModuleSelected,
  setProcesSelected,
  setShowMenuModule,
  setShowMenuProcess,
  setShowModalReplace,
  setShowModalDevices,
} = menuSlice.actions;
export default menuSlice.reducer;

export const getisShowMenuModule = (state) => {
  return state.menu.isShowMenuModule;
};
export const getModuleSelected = (state) => {
  return state.menu.moduleSelected;
};
export const getProcesSelected = (state) => {
  return state.menu.procesSelected;
};
export const getShowMenuProcess = (state) => {
  return state.menu.isShowMenuProcess;
};

export const getOptionsMenu = (state) => {
  const moduleSelected = state.persistedReducer.menuUser.filter((CIMS0003) => {
    return CIMS0003.MODIDXXX === state.menu.moduleSelected.MODIDXXX;
  });
  const processes = moduleSelected[0].CIMS0004;
  const processSelected = processes.filter((CIMS0004) => {
    return CIMS0004.PROIDXXX === state.menu.procesSelected.PROIDXXX;
  });
  return processSelected[0].CIMS0005;
};

export const getOptionsMenubyType = (optionsMenu, typeOption) => {
  let optionsMenuFilter = optionsMenu.filter((opt) => {
    return opt.TIPMENUX === typeOption;
  });
  return optionsMenuFilter;
};

export const getShowModalReplace = (state) => {
  return state.menu.showModalReplace;
};

export const getShowModalDevices = (state) => {
  return state.menu.showModalDevices;
};
