import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataEdit: {},
  initialChecks: {
    CHECKRIO: { USRADMRI: "NO", USRALIXX: "NO", USRBKBXX: "NO" },
    IDMODUXX: {
      100: "NO",
      101: "NO",
      102: "NO",
      103: "NO",
      104: "NO",
      105: "NO",
      106: "NO",
      107: "NO",
      108: "NO",
      109: "NO",
      110: "NO",
      111: "NO",
      112: "NO",
    },
    USERAUTO: { USRREQEL: "NO", USRREQAL: "NO", USRREQAU: "NO" },
  },
  autoPayment: "NO",
};

const usuariosSlice = createSlice({
  name: "usuarios",
  initialState: initialState,
  reducers: {
    setDataEdit: (state, action) => {
      state.dataEdit = action.payload;
    },
    setInitialChecks: (state, action) => {
      state.initialChecks = action.payload;
    },
    setAutoPayment: (state, action) => {
      state.autoPayment = action.payload;
    },
    resetValues: (state) => initialState,
  },
});

export const { setDataEdit, setInitialChecks, setAutoPayment, resetValues } = usuariosSlice.actions;
export default usuariosSlice.reducer;
//Export Data
export const getDataEdit = (state) => {
  return state.usuarios.dataEdit;
};
export const getInitialChecks = (state) => {
  return state.usuarios.initialChecks;
};
export const getAutoPayment = (state) => {
  return state.usuarios.autoPayment;
};
