/*
 * Componente contenedor de la pagina de inicio de sesion:
 * Agrega un contenedor a la pagina de inicio de sesion cuando debe cambiar contraseña o confirmar el codigo de seguridad
 */

import { Button, Spreen, SpreenBody, SpreenHeader, Stack, StackRow } from "codekit";

import Pwdrules from "../components/Pwdrules";
import Helpcard from "../components/Helpcard";

import { Link } from "react-router-dom";

const LoginPageChangePasswordWrapper = ({
  children,
  showPasswordRules = false,
  showLoginTicketCard = false,
  isSuccessPasswordRecovery = false,
}) => {
  return (
    <Spreen variant="change-password" srcBgImage="/images/recover-password.jpg">
      <SpreenHeader variant="change-password">
        {!isSuccessPasswordRecovery && (
          <Link to={"/login"} state={{ clearCodeConfirmation: true }}>
            <a href="/login" className="link-inline">
              <Button variant="secondary" size="m" icon="back-arrow" asSpan>
                Volver
              </Button>
            </a>
          </Link>
        )}
      </SpreenHeader>
      <SpreenBody variant="change-password" isSuccessPasswordRecovery={isSuccessPasswordRecovery}>
        <StackRow>
          <Stack gap={8}>{children}</Stack>
        </StackRow>
        {showPasswordRules && <Pwdrules />}
        {showLoginTicketCard && <Helpcard />}
      </SpreenBody>
    </Spreen>
  );
};

export default LoginPageChangePasswordWrapper;
