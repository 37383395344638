/**
 * Componente de reglas de contraseña: Permite observar las reglas que cumple contraseña en cuanto a su fuerza
 */

import { Box, Checkbox, Stack, StackRow, Title, Description } from "codekit";

import { useSelector } from "react-redux";

import { getPasswordStrength } from "../../libs/redux/slices/passwordSlice/passwordSlice";

const Pwdrules = () => {
  const { hasLength, hasUppercase, hasLowercase, hasNumber } = useSelector(getPasswordStrength);

  return (
    <Box spacing={8} bg="neutral-200" rounded="md">
      <Box mb={8}>
        <Title variant="title-2">Recuerda que tu contraseña debe contener:</Title>
      </Box>
      <Box mb={8}>
        <form>
          <Stack gap={8}>
            <StackRow>
              <Checkbox checked={hasLength} readOnly>
                Mínimo 8 caracteres.
              </Checkbox>
            </StackRow>
            <StackRow>
              <Checkbox checked={hasUppercase} readOnly>
                Mínimo una mayúscula.
              </Checkbox>
            </StackRow>
            <StackRow>
              <Checkbox checked={hasLowercase} readOnly>
                Mínimo una minúscula.
              </Checkbox>
            </StackRow>
            <StackRow>
              <Checkbox checked={hasNumber} readOnly>
                Mínimo un número.
              </Checkbox>
            </StackRow>
          </Stack>
        </form>
      </Box>
      <Description variant="description-2" color="neutral-400">
        Recuerda que no puedes asignar una contraseña que has usado en los últimos 6 meses.
      </Description>
    </Box>
  );
};

export default Pwdrules;
