import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setShowErrors } from "../Errorsxx/errorsSlice";
import QueryString from "qs";

const prepareQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_URL_BUS_API,
  mode: "cors",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const tokenBus = getState().persistedReducer.tokenBusApi;
    headers.set("Accept", "application/json");
    if (tokenBus) {
      headers.set("Authorization", `Bearer ${tokenBus}`);
    }
    return headers;
  },
  paramsSerializer: (params) => {
    return QueryString.stringify(params);
  },
});

const baseQuery = async (args, api, extraOptions) => {
  const result = await prepareQuery(args, api, extraOptions);

  // Manejo de errores
  if (result.error) {
    switch (result.error.status) {
      case 422:
      case 500:
        api.dispatch(setShowErrors(true));
        break;
      default:
        break;
    }
  }

  return result;
};

export const busapixxApiSlice = createApi({
  reducerPath: "busapixxApiSlice",
  baseQuery,
  endpoints: (builder) => ({
    loginBusApi: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: {
          USREMAXX: credentials.USRIDXXX,
          USRPASSX: credentials.USRIDXXX,
          ISSOCIAL: "NO",
        },
      }),
    }),
  }),
});

export const {
  useLoginBusApiMutation,
} = busapixxApiSlice;
