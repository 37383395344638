/*
 * Pagina de recuperacion de contraseña: Permite solicitar el enlace de recuperacion de contraseña
 * Tiene 4 estados posibles: Formulario de recuperacion, Confirmacion de codigo de seguridad,
 * Formulario de cambio de contraseña y pantalla de cambio exitoso
 */

import { Button, Spreen, SpreenBody, SpreenHeader, Stack, StackRow, StepperSimple } from "codekit";

import Recovpwd from "../components/Recovpwd";
import Codeconf from "../components/Codeconf";
import Helpcard from "../components/Helpcard";
import Changpwd from "../components/Changpwd";
import Changsuc from "../components/Changsuc";
import Pwdrules from "../components/Pwdrules";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getisSendMail, getisConfirmCode, getShowSuccessMessage } from "../libs/redux/slices/forgotSlice/forgotSlice";

const RecoverPasswordPage = () => {
  const isEmailSent = useSelector(getisSendMail);
  const isCodeConfirmed = useSelector(getisConfirmCode);
  const isShowSuccessMessage = useSelector(getShowSuccessMessage);

  let currentStep = null;
  let CurrentChildComponent = null;

  if (isCodeConfirmed) {
    currentStep = 3;
    CurrentChildComponent = <Changpwd origin="FORGOT-PAGE" />;
  } else if (isShowSuccessMessage) {
    CurrentChildComponent = <Changsuc />;
  } else if (isEmailSent) {
    currentStep = 2;
    CurrentChildComponent = <Codeconf origin="FORGOT-PAGE" />;
  } else {
    currentStep = 1;
    CurrentChildComponent = <Recovpwd />;
  }

  return (
    <Spreen variant="password-recovery" srcBgImage="/images/recover-password.jpg">
      <SpreenHeader variant="password-recovery">
        {!isShowSuccessMessage && (
          <Link to={"/login"} state={{ clearCodeConfirmation: true }}>
            <a href="/login" className="link-inline">
              <Button variant="secondary" size="m" icon="back-arrow" asSpan>
                Volver
              </Button>
            </a>
          </Link>
        )}
      </SpreenHeader>
      <SpreenBody variant="password-recovery" isSuccessPasswordRecovery={isShowSuccessMessage}>
        <StackRow>
          <Stack gap={8}>
            {!isShowSuccessMessage && <StepperSimple value={currentStep} steps={3} />}
            {CurrentChildComponent}
          </Stack>
        </StackRow>
        {isCodeConfirmed && <Pwdrules />}
        {!isShowSuccessMessage && !isCodeConfirmed && <Helpcard />}
      </SpreenBody>
    </Spreen>
  );
};

export default RecoverPasswordPage;
