/*
 * Pagina de inicio de sesion: Permite iniciar sesion en la plataforma
 * Tiene 4 estados posibles: Formulario de inicio de sesion, Confirmacion de codigo de seguridad,
 * Formulario de cambio de contraseña y pantalla de cambio exitoso
 */

import Changpwd from "../components/Changpwd/Changpwd";
import Changsuc from "../components/Changsuc/Changsuc";
import Codeconf from "../components/Codeconf/Codeconf";
import Lognform from "../components/Lognform/Lognform";
import LoginPageChangePasswordWrapper from "./LoginPageChangePasswordWrapper";

import { useDispatch, useSelector } from "react-redux";

import {
  getisChangePass,
  getisLoggedUserPassword,
  getShowSuccessMessage,
  signIn,
  signToken,
} from "../libs/redux/slices/authSlice/authSlice";
import { useEffect } from "react";
import { useLazyUserInfoQuery } from "../libs/redux/slices/authSlice/authApiSlice";
import { reset } from "../libs/redux/slices/passwordSlice/passwordSlice";

const LoginPage = () => {
  const isLoggedUserPass = useSelector(getisLoggedUserPassword);
  const isChangePass = useSelector(getisChangePass);
  const isShowSuccessMessage = useSelector(getShowSuccessMessage);
  const [infoUSerExt] = useLazyUserInfoQuery();
  const dispatch = useDispatch();

  let CurrentChildComponent = null;

  useEffect(() => {
    const useusrinfo = async () => {
      try {
        const userData = await infoUSerExt().unwrap();
        const shouldValidate = userData?.data?.USRIDXXX;
        const hasTokenAccess = userData?.data[0]?.token;
        if (shouldValidate) {
          dispatch(signIn({ shouldValidate, userData }));
        } else if (hasTokenAccess) {
          const { USRIDXXX } = userData?.data[0]?.INFOUSER;
          dispatch(signToken({ USRIDXXX, userData }));
          dispatch(reset());
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (!isLoggedUserPass) {
      useusrinfo();
    }
  }, [])

  if (isChangePass) {
    CurrentChildComponent = (
      <LoginPageChangePasswordWrapper showPasswordRules={true}>
        <Changpwd origin="LOGIN-PAGE" />
      </LoginPageChangePasswordWrapper>
    );
  } else if (isShowSuccessMessage) {
    CurrentChildComponent = (
      <LoginPageChangePasswordWrapper isSuccessPasswordRecovery={isShowSuccessMessage}>
        <Changsuc />
      </LoginPageChangePasswordWrapper>
    );
  } else if (isLoggedUserPass) {
    CurrentChildComponent = (
      <LoginPageChangePasswordWrapper showLoginTicketCard={true}>
        <Codeconf origin="LOGIN-PAGE" />
      </LoginPageChangePasswordWrapper>
    );
  } else {
    CurrentChildComponent = <Lognform />;
  }

  return <>{CurrentChildComponent}</>;
};

export default LoginPage;
