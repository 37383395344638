import { useEffect } from "react";
import { contextConfirm, closeConfrim, openConfirm } from "../../libs/redux/slices/Confirmx/confirmSlice";
import { useDispatch } from "react-redux";

let callBack;
export default function useCfrm() {
  const dispach = useDispatch();

  const onConfirm = () => {
    close();
    callBack(true);
  };
  const onCancel = () => {
    close();
    callBack(false);
  };
  const confirm = (contex) => {
    const { title, body } = contex;
    const data = {
      title,
      body,
    };
    dispach(contextConfirm(data));
    dispach(openConfirm(true));
    return new Promise((res, rej) => {
      callBack = res;
    });
  };
  const close = () => {
    dispach(closeConfrim(false));
  };

  return {
    confirm,
    onConfirm,
    onCancel,
  };
}
