/**
 * Slice para el maneja del estado para la parametrica de Proveedores
 */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  controlCheckbox: {
    PRORESIV: "NO",
    PROGRAXX: "NO",
    PROREGXX: "NO",
    PRORECXX: "NO",
    PROAUTXX: "NO",
    PRORFREN: "NO",
    PRORFIVA: "NO",
    PRORFCRE: "NO",
    PROAGERR: "NO",
    PROAGEIV: "NO",
    PROAGECR: "NO",
    PROAGEIC: "NO",
    PRORETIC: "NO",
    PROCOMIN: "NO",
    PRORESIM: "",
    PRORESAI: "NO",
    PRORECGF: "NO",
    PROAUTRN: "NO",
    PROAUTIV: "NO",
    PROAUTIC: "NO",
    PROAUTCR: "NO",
  },
  residenceTax: {
    country: "",
    department: "",
    city: "",
  },
  correspondence: {
    originCountry: "",
    originDepartment: "",
    originCity: "",
  },
  branches: {
    reteIca: [],
    noReteIca: [],
  },
  transports: [],
  isConsultBranchIca: false,
  isConsultBranchNoIca: false,
};

const providerSlice = createSlice({
  name: "provider",
  initialState: initialState,
  reducers: {
    setControlCheckbox: (state, action) => {
      const { inputName, value, valCheck } = action.payload;
      state.controlCheckbox = { ...state.controlCheckbox, [inputName]: !value ? valCheck : value };
    },
    setCountry: (state, action) => {
      state.residenceTax.country = action.payload;
    },
    setDepartment: (state, action) => {
      state.residenceTax.department = action.payload;
    },
    setCity: (state, action) => {
      state.residenceTax.city = action.payload;
    },
    setOriginCountry: (state, action) => {
      state.correspondence.originCountry = action.payload;
    },
    setOriginDepartment: (state, action) => {
      state.correspondence.originDepartment = action.payload;
    },
    setOriginCity: (state, action) => {
      state.correspondence.originCity = action.payload;
    },
    setBranchIca: (state, action) => {
      state.branches.reteIca = action.payload;
    },
    setBranchNoIca: (state, action) => {
      state.branches.noReteIca = action.payload;
    },
    setTransports: (state, action) => {
      state.transports = action.payload;
    },
    resetValues: (state) => {
      state.controlCheckbox = { ...initialState.controlCheckbox };
      state.residenceTax = { ...initialState.residenceTax };
      state.correspondence = { ...initialState.correspondence };
      state.branches = { ...initialState.branches };
      state.transports = initialState.transports;
      state.isConsultBranchIca = false;
      state.isConsultBranchNoIca = false;
    },
    setMasiveControlCheckbox: (state, action) => {
      state.controlCheckbox = action.payload;
    },
    setIsConsultBranchIca: (state, action) => {
      state.isConsultBranchIca = action.payload;
    },
    setIsConsultBranchNoIca: (state, action) => {
      state.isConsultBranchNoIca = action.payload;
    },
  },
});

export const {
  setControlCheckbox,
  setCountry,
  setDepartment,
  setCity,
  setOriginCountry,
  setOriginDepartment,
  setOriginCity,
  setBranchIca,
  setBranchNoIca,
  setTransports,
  resetValues,
  setMasiveControlCheckbox,
  setIsConsultBranchIca,
  setIsConsultBranchNoIca,
} = providerSlice.actions;

export default providerSlice.reducer;

export const getControlCheckbox = (state) => {
  return state.provider.controlCheckbox;
};
export const getCountry = (state) => {
  return state.provider.residenceTax.country;
};
export const getDepartment = (state) => {
  return state.provider.residenceTax.department;
};
export const getCity = (state) => {
  return state.provider.residenceTax.city;
};
export const getOriginCountry = (state) => {
  return state.provider.correspondence.originCountry;
};
export const getOriginDepartment = (state) => {
  return state.provider.correspondence.originDepartment;
};
export const getOriginCity = (state) => {
  return state.provider.correspondence.originCity;
};
export const getBranchIca = (state) => {
  return state.provider.branches.reteIca;
};
export const getBranchNoIca = (state) => {
  return state.provider.branches.noReteIca;
};
export const getTransports = (state) => {
  return state.provider.transports;
};
export const getIsConsultBranchIca = (state) => {
  return state.provider.isConsultBranchIca;
};
export const getIsConsultBranchNoIca = (state) => {
  return state.provider.isConsultBranchNoIca;
};
